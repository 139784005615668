@import "~@/erp/styles/variables/variables.scss";




























































































































































































































::v-deep .customer-companyAddress {
  min-width: 800px !important;

  .el-dialog__header {
    text-align: center;
    font-weight: 700;
    color: #333;
    font-size: 18px;

  }

  .el-dialog__body {

    padding: 20px 40px;

    .prompt-text {
      color: #d9001b;
      line-height: 25px;
      font-size: 13px;
    }

    .curren-title {
      font-size: 14px;
      color: #333;
      padding: 50px 0 20px;
    }

    .required-icon {
      .el-form-item__label {
        &::before {
          content: '*';
          color: #ff4d4f;
          margin-right: 4px;
        }
      }
    }
  }

  .el-dialog__footer {
    text-align: center;
  }
}
